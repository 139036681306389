/* Başlık Stilleri */
h2 {
  color: #f27127; /* Başlık rengi */
  text-align: center;
  margin-bottom: 20px; /* Başlık ile form arasındaki boşluğu ayarla */
  font-size: 22px; /* Başlık yazı tipi boyutunu ayarla */
  font-weight: bold; /* Kalın yazı tipi kullan */
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1); /* Hafif bir gölgelendirme ekle */
}

/* Container Stilleri */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #131028; /* Arka plan rengi */
}

/* Form Stilleri */
.form {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px; /* Yumuşak köşeler */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%; /* Formun genişliğini ayarlayın */
  max-width: 400px; /* Maksimum form genişliği */
  text-align: center;
  background-color: #fcf7f7; /* Form arka plan rengi */
}

/* Form Grubu Stilleri */
.form-group {
  margin-bottom: 20px; /* Form elemanları arasında boşluk */
}

/* Form Grubu Etiket Stilleri */
.form-group label {
  text-align: left;
  margin-bottom: 5px;
  color: #0e0d0d; /* Metin rengi */
}

/* Label Stilleri */
.label {
  display: block;
  text-align: left;
}

/* Input Grubu Stilleri */
.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Input Stilleri */
.input {
  width: 100%;
  padding: 12px; /* Input alanı boyutunu ayarlayın */
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px; /* Yazı tipi boyutunu ayarlayın */
}

.input#kullaniciAdi {
  width: 93.5%; /* veya başka bir genişlik değeri belirleyin */
}

/* Buton Stilleri */
.button {
  background: #f27127; /* Buton rengi */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px; /* Buton boyutunu ayarlayın */
  font-size: 16px; /* Yazı tipi boyutunu ayarlayın */
  cursor: pointer;
}

.button:disabled {
  background: #ccc; /* Devre dışı buton rengi (gri) */
  cursor: not-allowed;
}

/* Bildirim Stilleri */
.notification {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0;
  text-align: center;
  font-weight: bold;
  color: #fff; /* Metin rengi */
  background-color: #4CAF50; /* Yeşil arka plan rengi */
}

/* "Siteye Dön" Linki Stilleri */
.siteye-don-link {
  display: inline-block;
  color: #007BFF; /* Bağlantının metin rengi */
  text-decoration: none; /* Bağlantı altını çizmez */
  margin-left: 10px; /* Buton ile arasında boşluk bırakır */
  padding: 10px 20px; /* Dolgu ekler */
  border: 1px solid #007BFF; /* Kenarlık ekler */
  border-radius: 5px; /* Yumuşak köşeler */
  background-color: transparent; /* Arkaplan şeffaf */
  cursor: pointer;
}

/* "Siteye Dön" Linki Hover Stilleri */
.siteye-don-link:hover {
  background-color: #007BFF; /* Arkaplan rengini değiştirin */
  color: #fff; /* Metin rengini beyaza ayarlayın */
}

/* Yüklenme Çubuğu Stilleri */
.loading-bar-container {
  position: relative;
  height: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.loading-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #bbb2a4, #f27127);
  animation: loading-animation 1.5s infinite;
}

.bonus-talepleri-form {
  margin: 0 auto;
  max-width: 800px; /* Form ve tablonun genişliğini sınırladık */
}

.submit-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.separator {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}

.bonus-gecmisi {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9;
  overflow-x: auto; /* Mobilde tablo kaydırılabilir olur */
}

.bonus-gecmisi h3 {
  margin-bottom: 10px;
  font-size: 14px; /* Daha küçük başlık boyutu */
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 4px 8px; /* Daha küçük padding */
  text-align: left;
  font-size: 12px; /* Daha küçük metin boyutu */
}

table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.status-badge {
  display: inline-block;
  padding: 2px 4px; /* Daha küçük padding */
  border-radius: 12px;
  color: white;
  font-size: 10px; /* Daha küçük font boyutu */
  font-weight: bold;
}

.status-badge.rejected {
  background-color: #e74c3c; /* Kırmızı */
}

.status-badge.approved {
  background-color: #2ecc71; /* Yeşil */
}

.status-badge.pending {
  background-color: #3498db; /* Mavi */
}

.show-reason-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 2px 4px; /* Daha küçük buton boyutu */
  font-size: 10px; /* Daha küçük font boyutu */
  cursor: pointer;
  border-radius: 4px;
}

.show-reason-btn:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white;
  padding: 15px;
  border-radius: 8px;
  max-width: 90%; /* Modal genişliği mobil uyumlu */
  width: auto;
  text-align: center;
}

.modal-content h4 {
  margin-top: 0;
  font-size: 14px; /* Daha küçük başlık boyutu */
}

.modal-content button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 12px; /* Daha küçük font boyutu */
}

.modal-content button:hover {
  background-color: #0056b3;
}

/* Yüklenme Çubuğu Animasyonu */
@keyframes loading-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/* Medya Sorguları */
@media (max-width: 768px) {
  .form {
    width: 90%; /* Ekran daraldığında formun genişliğini artırın */
    max-width: none; /* Maksimum genişlik sınırlamasını kaldırın */
  }
}
